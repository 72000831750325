<template>
    <div>
        <mainheader></mainheader>
        <mainbox></mainbox>
        <section>
            <div class="container">
                <div class="article-container">
                    <div class="article-wrapper">
                        <span class="article-title">{{ translation.translate('petition', 'title') }}</span>
                        <br>
                        <p class="article-title-description">{{ translation.translate('petition', 'description') }}</p>
                    </div>
                </div>
                <div class="article-container">
                    <div class="petition-wrapper">
                        <div class="petition-content">
                            <span class="petition-content-text">{{ translation.translate('petition', 'label-theme')
                            }}</span>
                            <select v-model="select" id="articleSelect" class="petition-select petition-content-text">
                                <option value="1">{{ translation.translate('article1', 'title') }}</option>
                                <option value="2">{{ translation.translate('article2', 'title') }}</option>
                                <option value="3">{{ translation.translate('article3', 'title') }}</option>
                                <option value="4">{{ translation.translate('article4', 'title') }}</option>
                                <option value="5">{{ translation.translate('article5', 'title') }}</option>

                            </select>
                        </div>
                        <div class="petition-content ">
                            <span class="petition-content-text">{{ translation.translate('petition', 'label-name') }}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <input v-model="name" type="text" class="petition-select petition-content-text">
                        </div>
                        <div class="petition-content">
                            <span class="petition-content-text">{{ translation.translate('petition', 'label-phone')
                            }}</span>
                            <input v-model="phoneNumber" class="petition-select petition-content-text"
                                @change="maskPhoneNumber" value="+998" type="tel">
                        </div>
                        <div class="article-questions-btn-container">
                            <button class="article-questions-btn" @click="sendMessage()">{{
                                translation.translate('petition', 'petition-btn')
                            }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <mainfooter></mainfooter>
    </div>
</template>


<script>
import translate from '../../translation/translate'
import mainbox from "./mainbox.vue";
import mainheader from "./mainheader.vue";
import mainfooter from "./mainfooter.vue";

export default {
    name: 'petition',
    data() {
        return {
            translation: translate,
            select: 1,
            name: '',
            phoneNumber: '+998'
        }
    },
    props: {
        articleId: {
            type: Number,
            required: false
        }
    },
    components: {
        mainheader,
        mainbox,
        mainfooter
    },
    methods: {
        maskPhoneNumber(event) {
            const phoneInput = event.target;
            if (phoneInput.value.length > 4) {

                if (phoneInput.value.startsWith('+998')) {

                    let phoneNumber = phoneInput.value.replace(/\D/g, '');
                    if (phoneNumber.length > 12) {
                        phoneNumber = phoneNumber.substring(0, 12);
                    }
                    this.phoneNumber = '+' + phoneNumber
                }
                else {
                this.phoneNumber = '+998'
                }
            }
            else {
                this.phoneNumber = '+998'

            }

        },
        validation() {
            if (this.phoneNumber.length != 13) {
                return this.translation.translate('petition', 'phone-invalid')
            }
            if (this.name.length < 2) {
                return this.translation.translate('petition', 'name-invalid')
            }
            return true
        },
        sendMessage() {
            if (this.validation() === true) {

                var telegram_bot_id = "6216090042:AAFPUDOtJyDzZdshUEiBH0j0tupGI6aXA-A";
                var chat_id = -1001899599724;
                var name = this.name;
                var article = this.translation.translate(`article${this.select}`, 'title');
                var phoneNumber = this.phoneNumber;
                var content ="Edo.Faktura.uz"+"\n\nMaqola: " + article + "\n\nIsmi: " + name + "\nTelefon Raqam: " + phoneNumber;

                var url = "https://api.telegram.org/bot" + telegram_bot_id + "/sendMessage"
                var headers = {
                    "Content-Type": "application/json",
                    "cache-control": "no-cache"
                }
                var data = JSON.stringify({
                    "chat_id": chat_id,
                    "text": content
                })
                fetch(url, {
                    method: "POST",
                    mode: "cors",
                    cache: "no-cache",
                    headers: headers,
                    body: data
                }).then(response => {
                    if (response.status == 200) {
                        this.$router.push("thanks")
                    }
                })
            }
            else {
                alert(this.validation());
            }
        }

    },
  metaInfo() {
    return {
      title: "Оставить заявку на бесплатную консультацию",
      meta: [
        {
          name: "description",
          content:
            "Заполните форму ниже, и наша команда специалистов свяжется с вами в ближайшее время.",
        },
      ],
    };
  },
    mounted() {
        const hiddenP = document.getElementById('hiddenP');
        if (hiddenP) {
            this.select = hiddenP.innerHTML
        }

    }
}
</script>

<style scoped>
.article-wrapper {
    margin-top: 20px;
    max-width: 625px;
    text-align: center;
}

.article-title-description {

    padding: 5px;
    /* font-family: 'Roboto'; */
    font-family: Lab Grotesque,-apple-system,BlinkMacSystemFont,Arial,Liberation Sans,Nimbus Sans L,sans-serif;
    font-style: initial;
    font-weight: 400;
    font-size: 19px;
    line-height: 22px;
    text-align: center;
    color: #676767;
}

.petition-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    width: 55%;
}

.petition-content {

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.petition-content-text {

    /* font-family: 'Roboto'; */
    font-family: Lab Grotesque,-apple-system,BlinkMacSystemFont,Arial,Liberation Sans,Nimbus Sans L,sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #676767;

}

.petition-select {
    padding-left: 12px;
    padding-right: 12px;
    box-sizing: border-box;
    height: 38px;
    border: 1px solid #BCBCBC;
    border-radius: 5px;
    width: 70%;
}

.article-questions-btn-container {
    margin-top: 45px;
}

@media (max-width: 767px) {
    .petition-select {
        width: 95%;
    }
    .article-wrapper {
        width: 85%;
    }
    .petition-content {
        padding-left: 25px;
        padding-right: 15px;
        width: 100%;
    }

    .petition-wrapper {
        width: 100%;
    }
}
</style>